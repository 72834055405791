
  .listing {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    background: rgb(43, 43, 43);
    padding: 20px
  }


  .card-view {
    background: rgb(43, 43, 43);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card-view img {
    display: block;
    margin: 0 auto;
  }

  .card-view h3 {
    margin-top: 30px;
  }

  .card-view p {
    margin-top: 15px;
    color: #7c7878;
  }


  .price-details-container {
    max-width: 400px;
    /* Adjust the max-width as needed */
    margin: 0 auto;
    /* Center the container */
    padding: 20px;
    /* Optional padding */
  }

  .price-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    /* Optional padding for spacing between items */
  }

  .price-item p {
    margin: 0;
    /* Remove default margin from <p> elements */
  }

  .modal-header {
    /* background-color: #007bff; */
    background-color: #21272d;
    color: white;
  }



  .nav-tabs .nav-link {
    color: #007bff;
  }

  .nav-tabs .nav-link.active {
    background-color: #007bff;
    color: white;
  }

  .w-100 {
    width: 100%;
  }

  .modal-header .btn-close {
    background-color: transparent;
    /* Ensures the button background is transparent */
    color: white;
    /* Sets the text color of the close button to white */
  }

  .modal-header .btn-close::before {
    color: white;
    /* Sets the close icon color to white */
  }



  @media (max-width: 992px) {
    .view-right {
      align-items: center !important;
      margin-top: 50px;
    }
  }