.video-list {
    max-height: 416px;
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
  }
  
  .video-list::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  
  .video-parts {
    padding: 10px;
    margin: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .video-parts:hover {
    background-color: #f1f1f1;
  }
  
  .video-parts img {
    border-radius: 4px;
  }
  
  .video-parts p {
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .video-parts {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .video-parts img {
      margin-bottom: 10px;
    }
  }
  