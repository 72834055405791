/* Course.css */

.course-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

.course-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-img {
    object-fit: cover;
    height: 200px;
}

.card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.card-description {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.card-price {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-group .btn {
    width: 100%;
}

.mt-auto {
    margin-top: auto;
}
