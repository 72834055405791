.card-view {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card-view:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width:100px;
    height:200px;
    padding: 50px;
    margin: auto;
    padding-top: 15px;
    border-radius: 50%;
    /* transition: transform 0.3s; */
  }
  
  .card-image:hover {
    transform: rotate(360deg);
  }
  
  @media (max-width: 767.98px) {
    .card-view {
      margin-bottom: 20px;
    }
  }
  