.sidebar {
    width: 20%;
    /* background-color: black; */
    color: white;
    transition: width 0.3s ease;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

.sidebar.collapsed {
    width: 80px;
}

.sidebar-logo {
    padding: 20px;
    font-size: 24px;
    text-align: center;
    color: white;
    border-bottom: 1px solid #333;
}

.sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-header {
    padding: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #bbb;
}

.sidebar-item {
    padding: 10px 20px;
}

.sidebar-link {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
    font-size: 1rem;
}

.sidebar-link:hover {
    color: #00bcd4;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar.collapsed {
        width: 100%;
    }

    .sidebar-item {
        padding: 15px;
    }
}


/* navbar */
/* Styling for Navbar */
.navbar {
    color: white;
}

.navbar-toggler-icon {
    color: white;
}

.refresh-icon {
    cursor: pointer;
    color: #00bcd4;
    transition: color 0.3s ease;
}

.refresh-icon:hover {
    color: #007bff;
}

.navbar-nav .nav-item .nav-icon img {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.navbar-collapse {
    flex-grow: 1;
    justify-content: flex-end;
}

.dropdown-menu {
    background-color: #343a40;
    color: white;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
    color: white;
    padding: 10px 20px;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #007bff;
    color: white;
}

@media (max-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .navbar-collapse {
        flex-grow: unset;
    }

    .nav-item.dropdown {
        margin-left: auto;
    }

    .dropdown-menu {
        right: 0;
        left: unset;
    }
}