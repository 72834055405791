.home-page {
  position: relative;
  width: 100%;
}

.home-banner {
  position: relative;
  background-image: url('../../../public/youtube_banner.png');
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.centered-box {
  width: 80%;
  max-width: 500px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffcc; /* Semi-transparent white background */
  margin: -50px auto 0; /* Adjust margin to partially overlap the image */
  z-index: 1;
  position: relative;
  animation: fadeIn 2s ease-in-out;
  border-radius: 30px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-banner {
    height: 50vh;
  }

  .centered-box {
    width: 90%;
    height: 80px;
    margin: -40px auto 0;
  }

  .home-page {
    padding: 10px;
  }
}

/* Add additional styling for Cards and Course components */
