@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.sidebar{
    background-color: rgb(21, 21, 27);
}

.video-parts:hover{
    background-color: beige;
    
}

.hover-zoom:hover {
    transform: scale(1.05);
}

.feature-box:hover {
    background: darkgray;
    transform: scale(1.05);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}


/******************************/
